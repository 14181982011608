<template>
<div>
  <tabs>
    <tab-link name="account" exact>
      Overview
    </tab-link>
    <tab-link name="branding" exact>
      Branding
    </tab-link>
    <tab-link v-if="$root.isSuper()" name="billing" exact>
      Billing
    </tab-link>
  </tabs>
  <router-view />
</div>
</template>
<script>

export default {

  async beforeCreate() {
    await this.$store.dispatch('auth/loadAccount')
    await this.$store.dispatch('auth/loadAccountAddress').then((data) => {
      this.$store.commit('location/updateAddress', data)
    })
  },

  beforeDestroy() {
    this.$store.commit('location/clearLocation')
  }

}
</script>